import { cva } from 'class-variance-authority';

export const cvaRoot = cva([
  'SelectableStyles-cvaRoot',
  'relative',
  'block',
  'not-last:mb-1.6',
  'cursor-pointer select-none',
  'group',
]);

export const cvaInput = cva([
  'SelectableStyles-cvaInput',
  'hidden',
  'peer',
]);

export const cvaInner = cva(
  [
    'SelectableStyles-cvaInner',
    'block',
    'relative',
    'pr-3.2',
    'transition-colors duration-300',
  ],
  {
    variants: {
      inDropdown: {
        true: [
          'py-1.6 px-2.4 pr-6.4',
          'group-hover:bg-cMediumGreyOnWhite',
        ],
        false: ['before:left-0'],
      },
    },
    defaultVariants: {
      inDropdown: false,
    },
  }
);

export const cvaCheckIcon = cva(
  [
    'SelectableStyles-cvaCheckIcon',
    'absolute top-1/2 -translate-y-1/2 mt-0.1 z-[1]',
    'w-1.6 h-1.6',
    'opacity-0',
    'transition-opacity duration-300',
    'peer-checked:opacity-100',
  ],
  {
    variants: {
      inDropdown: {
        true: ['right-2.4'],
        false: ['right-0'],
      },
    },
    defaultVariants: {
      inDropdown: false,
    },
  }
);

export const cvaTitle = cva([
  'SelectableStyles-cvaTitle',
  'inline-block',
  'text-bodyM',
]);
