import { twMergeExt } from '@/shared/utils/twMergeExt';
import { Field, useField } from 'formik';
import { FC } from 'react';
import {
  cvaCheckIcon,
  cvaInner,
  cvaInput,
  cvaRoot,
  cvaTitle,
} from './SelectableStyles';
import IconMark from '/public/icons/check.svg';

interface Props {
  type?: 'checkbox' | 'radio';
  name: string;
  value: string | number;
  title: string | number;
  className?: string;
  innerClassName?: string;
  inDropdown?: boolean;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const Selectable: FC<Props> = ({
  type = 'checkbox',
  name,
  value,
  title,
  className,
  innerClassName,
  inDropdown,
  onChange,
}) => {
  const [field] = useField({
    name,
    type,
    value: value.toString(),
  });
  return (
    <label
      className={twMergeExt(cvaRoot(), className)}
    >
      <Field
        type={type}
        name={name}
        value={value}
        className={cvaInput()}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement>
        ) => {
          field.onChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
      />
      <IconMark
        className={cvaCheckIcon({ inDropdown })}
      />
      <span
        className={cvaInner({
          inDropdown,
          className: innerClassName,
        })}
      >
        <span className={cvaTitle()}>
          {title}
        </span>
      </span>
    </label>
  );
};

export default Selectable;
